import { Tooltip } from 'bootstrap';
import React, { ChangeEvent, ChangeEventHandler, FocusEventHandler, MouseEventHandler, useRef, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import InputWithSelectorField from '../../components/InputWithSelectorField';

type DetailProps = {
    key: number;
    propertyName: string;
    defaultValue: any;
    rowColor: string;
    editType: string;
    className?: string;
    pattern?: string;
    showInputEle: boolean;
    hideOnNull: boolean;
    handleDoubleClick: MouseEventHandler;
    handleChange: ChangeEventHandler<HTMLElement>;
    possibilities?: string[] | null;
    enumMap?: { [key: number]: string } | null;
    description?: string | null;
    //handleBlur: FocusEventHandler<HTMLInputElement>;
};

const getWidth = (text: string) => {
    var width = (text.length * 7) + 14;
    return width + "px";
}

const getInputElement = (fixedInputText: string | null, defaultValue: any, pattern: string | undefined, isRequired: boolean, handleChange: ChangeEventHandler<HTMLElement>) => {

    if (fixedInputText === null) {
        return (
            <span><Form.Control
                type="text"
                defaultValue={defaultValue}
                onChange={(e) => { handleChange(e); }}
                size="sm"
                pattern={pattern}
                required={isRequired}
                onBlur={(e) => e.target.reportValidity()}
            /></span>);
    }
    return (<><div style={{ whiteSpace: "nowrap", overflow: "hidden", display: "flex" }}>
        <Form.Control onClick={(e) => {
            (document.querySelector("#input1") as HTMLElement)?.scrollIntoView(); (document.querySelector("#input1") as HTMLElement)?.focus();
        }} className="fixedInputText, d-none d-md-block" size="sm" type="readonly" disabled={false} defaultValue={fixedInputText} style={{ backgroundColor: "white", paddingRight: 0, borderRightColor: "white", minWidth: getWidth(fixedInputText), maxWidth: getWidth(fixedInputText), zIndex: 1 }} />        
        <Form.Control style={{ marginLeft: -5, paddingLeft: 5, borderLeftColor: "white", zIndex: 0 }}
            className="d-none d-md-block"
            type="text"
            size="sm"
            id="input1"
            defaultValue={defaultValue}
            onChange={(e) => { handleChange(e); }}
            pattern={pattern}
            required={isRequired}
            onBlur={(e) => e.target.reportValidity()} />        
    </div><div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
            <Form.Control onClick={(e) => {
                (document.querySelector("#input2") as HTMLElement)?.focus();
            }} className="d-md-none" size="sm" type="readonly" disabled={false} defaultValue={fixedInputText} style={{ backgroundColor: "white", paddingRight: 0 }} />
            <Form.Control
                className="d-md-none"
                size="sm"
                id="input2"
                defaultValue={defaultValue}
                onChange={(e) => { handleChange(e); }}
                pattern={pattern}
                required={isRequired}
                onBlur={(e) => e.target.reportValidity()} />
</div></>);
}

const DetailItem = ({ key, propertyName, defaultValue, rowColor, className, editType, pattern, showInputEle, hideOnNull, handleDoubleClick, handleChange, description, possibilities = [], enumMap = {} }: DetailProps) => {
    const tooltipRef = useRef<HTMLSpanElement>(null);

    const isRequired = (pattern: string | undefined) => {
        var ranges = pattern?.split("]{") ?? [];

        if (ranges.length > 0) {
            var range = ranges.pop() ?? "";
            var minValue = Number(range.split(",")?.at(0) ?? 0);
            if (minValue > 0) {
                return true;
            }
        }

        return false;
    } 

    let fixedInputText = null;

    if (propertyName.includes("~|~")) {
        const tmp = propertyName.split("~|~");
        propertyName = tmp[0];
        fixedInputText = tmp[1];
    }

    useEffect(() => {        
        if (tooltipRef?.current) {
            const tooltip = new Tooltip(tooltipRef.current, {
                container: 'body',
                trigger: 'click',
                placement: 'right'
            });            

            return () => {
                tooltip.dispose();                
            };
        }
    }, []);


    return (
        hideOnNull && (defaultValue === null || defaultValue === undefined) ? <></> :
        <>
                <Row key={key} className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table table-column-first q-vend-detail-table-col">{propertyName}{(description !== null && description !== undefined && (
                        <span data-bs-html="true" title={description} ref={tooltipRef} className="btn-xs">
                            <svg className="flex-shrink-0 bi me-2 icon-24" width="18" height="18" style={{ marginLeft:"5px" }}>
                                <use href="#info-lamp"></use>
                            </svg>
                        </span>
                    ))}</Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table q-vend-detail-table-col" onDoubleClick={(e) => { handleDoubleClick(e); }}>{
                        showInputEle ? editType === "string" ? getInputElement(fixedInputText, defaultValue, pattern, isRequired(pattern), handleChange) : editType === "checkbox" ? (<span>
                            <Form.Check
                                defaultChecked={defaultValue.toString().toLowerCase() === "true" ? true : false}
                                onChange={(e) => { e.target.value = e.target.checked ? "True" : "False"; handleChange(e); }}
                            /></span>)
                        : editType == "list" && possibilities ? <Form.Select className="form-control" style={{ padding: "0.3rem 3rem 0.3rem 1rem" }} defaultValue={defaultValue} onChange={(e) => {
                            console.log("e:" + e.target.value); handleChange(e);
                        }}>
                            {possibilities.map((v: string) => (<option>{v}</option>))}
                        </Form.Select>
                            : editType === "string-language" && possibilities ? <InputWithSelectorField options={possibilities} initialTextPerOption={JSON.parse(defaultValue)} onChange={(e) => {
                                    handleChange(e);
                                }} /> 
                            :  (<span>{defaultValue}</span>)

                    : editType === "checkbox" ?
                            (<span><Form.Check
                                defaultChecked={defaultValue.toString().toLowerCase() === "true" ? true : false}
                                onChange={(e) => { e.target.value = e.target.checked ? "True" : "False"; handleChange(e); }}
                                    disabled={true} /></span>) :
                                (<span>{enumMap !== null && enumMap !== undefined && enumMap[defaultValue] !== undefined ? enumMap[defaultValue] : editType === "string-language" ? JSON.parse(defaultValue)["NL"] : fixedInputText != null ? fixedInputText + defaultValue : defaultValue}</span>)
                }
                </Col>
            </Row>
        </>
    );
};

DetailItem.defaultProps = {
    possibilities: []
};

export default DetailItem;
