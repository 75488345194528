import React, { useState } from 'react'
import { Transaction } from '../../services/openapi'

type Props = { 
    saveTx: (e: React.FormEvent, formData: Transaction) => void 
}

const defaultTx: Transaction = {
    acquirerToken: 'acquirerToken',
    authorizationCode: 'authorizationCode',
    cardToken: 'cardToken',
    chargedAmount: "0,01",
    terminalIdentifier: '1',
    statusCodeDescription: '',
    transactionDateTime: Date.now.toString()
}
const AddTx: React.FC<Props> = ({ saveTx }) => {
  const [formData, setFormData] = useState<Transaction>(defaultTx)

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  return (
    <form className='Form' onSubmit={(e) => saveTx(e, formData)}>
      <div>
        <div>
          <label htmlFor='acquirerToken'>AcquirerToken</label>
          <input onChange={handleForm} type='text' id='acquirerToken' />
        </div>
        <div>
          <label htmlFor='authorizationCode'>AuthorizationCode</label>
          <input onChange={handleForm} type='text' id='authorizationCode' />
        </div>
        <div>
          <label htmlFor='cardToken'>CardToken</label>
          <input onChange={handleForm} type='text' id='cardToken' />
        </div>
        <div>
          <label htmlFor='chargedAmountInCents'>ChargedAmountInCents</label>
          <input onChange={handleForm} type='number' id='chargedAmountInCents' />
        </div>
        <div>
          <label htmlFor='terminalIdentifier'>TerminalIdentifier</label>
          <input onChange={handleForm} type='text' id='terminalIdentifier' />
        </div>
        <div>
          <label htmlFor='transactionDateTime'>TransactionDateTime</label>
          <input onChange={handleForm} type='text' id='transactionDateTime' />
        </div>
      </div>
      <button disabled={formData === undefined ? true: false} >Add Transaction</button>
    </form>
  )
}

export default AddTx
